import React from "react"
import styled from "styled-components"
import LayoutNavbar from "../components/layout-navbar"
import Header from "../components/header"

class Layout extends React.Component {
  render() {
    const { isMainPage, children } = this.props
    return (
      <Wrapper>
        <link
          href="https://fonts.googleapis.com/css?family=Poppins"
          rel="stylesheet"
        />
        <div>
          <Header />
          <LayoutNavbar isMainPage={isMainPage} />
          <main>{children}</main>
        </div>
        <Footer>© {new Date().getFullYear()}, BoMed</Footer>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  padding-bottom: 24px;
`

export default Layout
