import React from "react"
import { FaPhoneAlt, FaRegEnvelope } from "react-icons/fa"
import { BsCalendar } from "react-icons/bs"

class Header extends React.Component {
  render() {
    return (
      <header id="top">
        <div class="container">
          <div class="row">
            <div class="col align-self-end" style={{ textAlign: `right` }}>
              <span>
                <FaPhoneAlt className="phone-icon" />
                <a href="tel:+48183514444">+48 18 35 14 444</a>
              </span>
              <span>
                <BsCalendar className="date-icon" /> 8:00 - 18:00 (Pon-Piątek)
              </span>
              <span>
                <FaRegEnvelope className="email-icon" />
                <a href="mailto:administracja@bomed-bobowa.pl">
                  administracja@bomed-bobowa.pl
                </a>
              </span>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
