import React from "react"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"

export default function LayoutNavbar(props) {
  const linkStyle = {
    paddingRight: `20px`,
    paddingLeft: `20px`,
    boxShadow: `none`,
    fontSize: `13px`,
    fontWeight: `600`,
  }
  const navbarClassName = props.isMainPage ? "navbar" : "navbar navbar-post"
  const navbarContainerClassName = props.isMainPage
    ? "navbar-container"
    : "navbar-container container"
  return (
    <Navbar id="navbar" className={navbarClassName} expand="lg">
      <div class={navbarContainerClassName}>
        <Navbar.Brand href="/" style={{ boxShadow: "none" }}>
          <img style={{ margin: 0, height: 70 }} src="/logo.png" alt="BOMED" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{ justifyContent: "flex-end" }}
        >
          <Nav>
            <Nav.Link href="#top" style={linkStyle} onClick={smoothScroll}>
              Start
            </Nav.Link>
            <Nav.Link href="#news" style={linkStyle} onClick={smoothScroll}>
              Aktualności
            </Nav.Link>
            <NavDropdown
              title="Kontakt"
              style={linkStyle}
              className="navbar-contact"
            >
              <NavDropdown.Item
                href="#contact"
                style={linkStyle}
                onClick={smoothScroll}
              >
                Bobowa
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#contact-filia"
                style={linkStyle}
                onClick={smoothScroll}
              >
                Siedliska
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#contact-reh"
                style={linkStyle}
                onClick={smoothScroll}
              >
                Rehabilitacja
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
  function smoothScroll(e) {
    e.preventDefault()
    window["smoothScroll"](e.target.getAttribute("href"))
  }
}
